body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.moving-shadow {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  /* background-color: hsla(230, 40%, 50%, 1); */
}

.moving-shadow:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  height: 100%;
}


.moving-shadow h3 {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  color: white;
  font-size: clamp(2em, 5vw, 3em);
  line-height: 1em;
  letter-spacing: 0.1em;

  transition: 0.2s;
  text-shadow: .03em .03em 0 var(--primary-color);
}

.moving-shadow h3:hover {
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white; */
  transition: 0.2s;
  transform: scale(1.1);
  /* color: var(--secondary-color) !important; */

}


.moving-shadow h3:after {
  content: attr(data-shadow);
  position: absolute;
  width: 100%;
  top: .07em;
  left: .07em;
  z-index: -1;
  text-shadow: none;
  background-image:
    linear-gradient(45deg,
      transparent 45%,
      hsla(48, 20%, 90%, 1) 45%,
      hsla(48, 20%, 90%, 1) 55%,
      transparent 0);
  background-size: .2em .2em;
  -webkit-text-stroke-width: 0px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shad-anim 25s linear infinite;
}

:root {
  --primary-color: #EE8A2C;
  --secondary-color: #11A8E4;
  --secondary-color-light: #56bcf7;
  --tertiary-color: #0e68bb;
  --primary-color-dark: #0e346d;
  --primary-color-transparent: rgba(129, 5, 211, 0.178);
  --primary-color-half: #ebc31381;
  --primary-color-light: #fde681;
  --light-grey: rgb(214, 214, 214);
  --grey: rgb(138, 138, 138);
  --green: rgb(65, 201, 60);

  --dark-color: hsl(0, 2%, 18%);
  --background-color: rgb(170, 20, 14);
  --background-color-dark: #ccd1d6;
  --background-color-gradient: linear-gradient(145deg, #1e2024, #23272b);
  --shadow-primary: 3px 1px 11px var(--primary-color),
    -3px -2px 9px var(--primary-color-light);
  --shadow-black-light: 1px 1px 3px #6868682d,
    -2px -2px 8px #70707031;
  --shadow-black: 2px 2px 5px #42424244,
    -3px -3px 10px #55555538;
  --shadow-white: 3px 4px 10px #ffffff54,
    -3px -3px 10px #d6d6d660;
  --shadow-black-large: 5px 5px 26px -4px rgba(0, 0, 0, 0.7);


  --smallest-font-size: clamp(8.5px, 2vw, 9.5px);
  --tiny-font-size: clamp(8px, 3vw, 12px);
  --body-font-size: clamp(11px, 2vw, 15px);
  --large-font-size: clamp(32px, 8vw, 75px);
  --big-font-size: clamp(30px, 7.7vw, 50px);
  --h1-font-size: clamp(28px, 5vw, 39px);
  --h2-font-size: clamp(23px, 5vw, 33px);
  --h3-font-size: clamp(18px, 4vw, 25px);
  --h4-font-size: clamp(15px, 2.2vw, 21px);
  --h5-font-size: clamp(14px, 2vw, 18px);

  --primary-text-outline: -3px -3px 0 var(--primary-color), 3px -3px 0 var(--primary-color), -3px 3px 0 var(--primary-color), 3px 3px 0 var(--primary-color);
  --white-text-shadow-thick: 2px 2px 2px white;
  --black-text-shadow-thick: 1px 2px 2px rgba(0, 0, 0, 0.75);
  --primary-text-shadow-thick: 2px 2px 2px var(--primary-color);
  --secondary-text-shadow-thick: 2px 2px 2px var(--secondary-color);
  --primary-text-shadow-blurred: 3px 3px 12px var(--primary-color);
  --black-text-shadow-blurred: 2px 2px 2px rgba(0, 0, 0, 0.25);

  --border-radius-small: 0.3rem;
  --border-radius: .6rem;
  --border-radius-large: 25px;
}