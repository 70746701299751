.MainFeaturesContainer {
    background-color: #333333;

}

.cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
    padding: 0 3vw;
    /* Automatically adjust the number of columns based on card's min-width */
    gap: 25px;
    /* Adjust this to control the spacing between cards */
    justify-items: center;
    /* Center cards horizontally */
    margin-bottom: 2.3rem;
}

.clickMe {
    cursor: pointer;
    text-transform: uppercase
}

.FeaturesContainer {
    display: flex;
    flex-wrap: wrap;
}

#BlueBox {
    background-color: #11A8E4;
    border-radius: 30% 0;
    overflow: hidden;
    align-items: flex-end;
}

#OrangeBox {
    background-color: #EE8A2C;
    border-radius: 0 30%;
    overflow: hidden;
    align-items: flex-end;
}

.MainFeaturesSmallText {
    overflow: hidden;
    font-size: 30px;

}

.MainFeaturesBox {
    display: flex;
    flex: 1;
    margin-bottom: 3vw;
    min-width: 45%;
    max-width: 50%;
    height: 50vh;
    font-size: 50px;
    /* background-color: green; */
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.MainFeaturesTextBox {
    text-align: center;
    align-content: center;

}

.MainFeaturesBox img {
    justify-items: center;
}