.PrivacyContainer {
    padding: 1vw 6vw 6vw;
    background-color: #fff4ea;
    color: #555555
}

.heading2 {
    padding: 1.2rem 0;
    font-size: 2rem;
    font-weight: 600;
    color: orange;
}

.heading3 {
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: black;
}

.heading4 {
    padding: 0.8rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #363636
}

.bulletPoint {
    margin-left: 3vw;
}