.slide-container {
    display: flex;
    flex-direction: column;
    background: linear-gradient(159deg, rgb(41, 41, 56) 0%, rgb(41, 41, 56) 100%);
    padding: 1.5rem 1.5rem;
    margin: 0.2rem 0.5rem;
    height: 300px;
    justify-content: space-between;
    /* Set height to 100% to occupy the entire height of the parent */
    border-radius: 10px;
}

.slide-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slide-header .quote {
    font-size: 3rem;
    color: #E9974A;
    opacity: 0.8;
}

.slide-header .star {
    color: #ffcd3c;
    font-size: 2rem;
}

.slide-body {
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.slide-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
}

.likesContainer {
    /* position: absolute; */
    display: flex;
    /* right: 2rem; */
    gap: 0.7rem;
    flex-direction: row;
    align-items: center;

}

.likesContainer p {
    font-size: 1.2rem;
    align-content: center;
}

.slide-footer img {
    width: 5rem;
    height: 5rem;
    border-radius: 50px;
    object-fit: cover;
}

.no-image {
    width: 5rem;
    height: 5rem;
    aspect-ratio: 1;
    border-radius: 50%;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    line-height: 4rem;
    /* Adjust to match the actual height of the text */
    background-color: #E9974A;
    display: flex;
    align-items: center;
    justify-content: center;
}


.footer-name {
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0;
}

.slide-footer p {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.500);
}

@media(max-width: 580px) {
    .slide-footer h1 {
        font-size: 1rem;
    }
}

@media(max-width: 538px) {
    .slide-footer h1 {
        font-size: 0.9rem;
    }

    .slide-footer p {
        font-size: 0.6rem;
    }
}