@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #fcfcfc;
}

.navContainer {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.navbar {
    height: 60px;
    background-color: #EE8A2C;
    position: relative;
    padding-bottom: 10px;
    box-shadow: 0px 7px 18px -15px #000000a8;
}

.logo {
    color: white;
    font-size: 48px;
    font-weight: 300;
    font-family: 'Francois One';
    /* margin-left: -100px; */
    /* margin-top: 15px; */

}

.logoText {
    position: relative;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
}

.logoText::before {
    content: '';
    position: absolute;
    bottom: 6px;
    left: 3px;
    width: 89px;
    height: 4.5px;
    background-color: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
}

.menu-icon {
    display: none;
}

.nav-download-button {
    display: flex;
    background: linear-gradient(120deg, #3b3b3b, #252525);
    border-radius: 15px;
    padding: 0.5rem 0.8rem;
    width: auto;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 600 !important;
    justify-content: center;
    align-items: center;
    color: white !important;
    border: 3px solid #EE8A2C;
    transition: 0.2s;
}

.nav-download-button:hover {
    transition: 0.2s;
    transform: scale(0.92);
    background: linear-gradient(120deg, #11A8E4, #0a89bb);
    border: 3px solid white;
}

/* .nav-elements {} */

.nav-elements ul {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 3vw;
}

.nav-elements ul a {
    font-size: 18px;
    font-weight: 500;
    padding: 0.2rem 1rem;
    color: #383737;
    text-decoration: none;
    transition: 0.2s;
    border-radius: var(--border-radius);
    text-transform: uppercase;
}

.nav-elements ul a.active,
.nav-elements ul a:hover {
    color: #EE8A2C;
    font-weight: 500;
    position: relative;
    background-color: white;



    box-shadow: 0px 0px 5px #ffe8cd;
    /* font-weight: 700; */
    transition: 0.2s;
}

.nav-elements ul a:hover {
    transform: scale(0.95);
}

/* .nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
} */

@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }
}

@media (max-width: 700px) {
    .logo {
        color: white;
        font-size: 30px;
        font-weight: 300;
        font-family: 'Francois One';
        margin-top: 3px;
        /* margin-left: -100px; */
        /* margin-top: 15px; */

    }

    .logoText::before {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 1px;
        width: 56px;
        height: 3px;
        background-color: white;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    }

    .menu-icon {
        padding-top: 15px;
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 60px;
        background-color: #EE8A2C;
        width: 0px;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;
    }

    .nav-elements.active {
        width: 270px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }

    .navbar {
        height: 50px;

    }
}