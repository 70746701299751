.AboutContainer {
    background-color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
}

.AboutBox {
    background-color: #fff4ea98;
    color: rgb(46, 46, 46);
    padding: 1vw 5vw 5vw;
    width: 80%;
    border-radius: 20px;
    box-shadow: 3px 3px 2px #0000;
    line-height: 1.3;
}

.shadow {
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.6);
}

.AboutTextContainer {
    font-size: 2.5vh;
    padding-top: 1rem;

}

@media (max-width: 700px) {
    .AboutTextContainer {
        font-size: calc(12px + 1vw)
    }

    .shadow {
        text-shadow: 1px 1px 0.5px rgba(0, 0, 0, 0.6);
        font-size: calc(14px + 1vw);
    }

}