/* ------------------------ */

.rating-container {
    width: 90%;
    max-width: 1650px;
    margin: 0 auto;
    padding: 0.2rem 0 4.2rem 0;
    font-family: 'Poppins', sans-serif;
}

.rating-container .orange-heading {
    font-weight: 700;
    color: #E9974A;
    font-size: 2rem;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

@media(max-width:840px) {
    .rating-container {
        width: 90%;
    }
}

.rating-container .sub-heading {
    padding-top: 1rem;
    font-weight: 800;
    margin: 0;
    margin-bottom: 1.5rem;
    font-size: 3rem;
    text-transform: capitalize;
}

.testimonials {
    margin-top: 2rem;
    position: relative;

}

.testimonial-buttons {
    position: absolute;
    right: 0.7rem;
    bottom: -3.2rem;
}

.testimonial-buttons button {
    background-color: transparent;
    margin-left: 0.5rem;
    border: none;
    color: #E9974A;
    cursor: pointer;
    font-size: 1.9rem;
}

@media(max-width:530px) {
    .testimonial-buttons {
        display: none;
    }
}

.slides {
    display: flex;
    overflow: hidden;
    height: 100%;
}

.slide {
    /* Adjust the width based on the number of visible reviews */
    box-sizing: border-box;
    padding: 0 8px;
    /* Add padding or adjust as needed */
}

.splide__arrows {
    position: absolute;
    right: 0.7rem;
    bottom: -4rem;
}

.splide__arrows button {
    margin: 0;
    background-color: transparent;
    font-weight: 900;
    border: none;
    color: #E9974A;
    cursor: pointer;
    font-size: clamp(2.5rem, 5vw, 3rem);
}

.splide__arrows button svg {
    filter: drop-shadow(1px 1px 5px rgba(255, 255, 255, 0.2));
}