.tag-list {
    width: 100%;
    font-weight: 600;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infinite-tags-container {
    background-color: #333333;
}

.text-container {
    padding: 0 10vw 0;
    display: grid;
}

.infinite-tags-text {
    flex: 1;
    /* font-family: 'Francois One'; */
    font-size: clamp(2.7rem, 4vw, 3.5rem);
    line-height: clamp(2.9rem, 4vw, 3.8rem);
    padding: 5px 20px;
    font-weight: 700;
    text-transform: uppercase;
    /* margin-right: 15px; */
    letter-spacing: 0.4rem;
    text-align: center;
    align-self: center;
}


.bullet-point-box span {
    color: #11A8E4;
}

.infinite-tags-text span {
    color: #11A8E4;
}

.inner {
    width: 100%;
    display: flex;
    gap: 1.2rem;
    animation: loop 40s linear infinite;
}

.tag {
    display: flex;
    align-items: center;
    gap: 0 0.4rem;
    color: #e2e8f0;
    font-size: 1rem;
    background-color: #EE8A2C;
    padding: 0.5rem 1.2rem;
    border-radius: 0.4rem;
    box-shadow:
        0 0.1rem 0.2rem #00000033,
        0 0.1rem 0.5rem #0000004d,
        0 0.1rem 1.5rem #00000066;
    white-space: nowrap;
}

.tag span {
    font-size: 1.2rem;
}

.tag img {
    aspect-ratio: 1;
    height: clamp(2rem, 3vw, 3.5rem)
}

.fade {
    position: relative;
    background: linear-gradient(90deg,
            #1e293b, transparent 10%,
            transparent 70%, #1e293b);
    inset: 0;
    pointer-events: none;
}

@keyframes loop {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-380%);
    }
}

@media (max-width: 700px) {


    @keyframes loop {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-900%);
        }
    }

}

.bullet-points-container {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    row-gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.bullet-point-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.7rem;
    min-width: 200px;
    text-wrap: balance;

}

.bullet-point-box-description {
    font-weight: 600;
    transition: 0.2s;
    border-radius: var(--border-radius);
    /* background-color: #ffffff05; */
    padding: 0.5rem;
}

.bullet-point-box-description:hover {
    transition: 0.2s;
    transform: translateY(10px);
    background-color: #ffffff1a;
    box-shadow: 1px 1px 8px #00000033;
}

.bullet-point-box img {
    transition: 0.5s;
}

.bullet-point-box img:hover {
    transition: 0.5s;
    transform: scale(0.8) rotate(360deg);
}