/* styles.css */

.wrapper {
    padding: 4rem;
    background-color: #1f2937;
    display: grid;
    place-content: center;
}

.open-button {
    background: linear-gradient(to right, #ff9900, #ff6600);
    color: #ffffff;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    transition: opacity 0.3s;
}

.open-button:hover {
    opacity: 0.9;
}

.modal-container {
    background-color: rgba(31, 41, 55, 0.2);
    backdrop-filter: blur(20px);
    padding: 2rem;
    position: fixed;
    inset: 0;
    z-index: 50;
    display: grid;
    place-items: center;
    overflow-y: scroll;
    cursor: pointer;
}

.modal-content {
    /* background: linear-gradient(to bottom right, #8b5cf6, #4c4ee4); */
    background: linear-gradient(to bottom right, #ff9900, #ff6600);
    color: #ffffff;
    padding: 1.5rem;
    border-radius: 0.375rem;
    width: 100%;
    max-width: 32rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    cursor: default;
    position: relative;
    overflow: hidden;
}

.alert-icon {
    color: rgba(255, 255, 255, 0.1);
    transform: rotate(12.5deg);
    font-size: 250px;
    position: absolute;
    top: -5rem;
    left: -5rem;
}

.modal-content-inner {
    z-index: 10;
}

.modal-content-inner p {
    margin-bottom: 1.5rem;
}

.button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.button-group button {
    flex: 1;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s, opacity 0.3s;
}

.button-group button:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

.button-group button.accept {
    background-color: #ffffff;
    color: #ff6600;
}

.button-group button.decline {
    background-color: transparent;
    color: #ffffff;
}

.i-icon-middle {
    background-color: #ffffff;
    width: 4.5rem;
    height: 4.5rem;
    /* margin-bottom: 0.2rem; */
    border-radius: 50%;
    font-size: 2.2rem;
    color: #ff6600;
    display: grid;
    place-items: center;
    margin-left: auto;
    margin-right: auto;
}

.big-text {
    font-size: 1.875rem;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 0.5rem;
    text-align: center;
}

.text {
    margin-bottom: 6;
    color: #ffff;
    padding-inline: 0.5rem;
}