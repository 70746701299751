.contactContainer {
    background-color: rgb(51, 51, 51);
    padding: 4vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.formContainer {
    /* Removed justify-content: center; */
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
}

.form-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.form-group label {
    flex: 1;
    margin-right: 2.5vw;
}

.form-control {
    border-width: 0;
    width: 40vw;
    display: flex;
    flex: 3;
    font-size: 1.3rem;
    background-color: #EE8A2C;
    box-shadow: 2px 7px 18px -12px #000000a8;
    padding: 1.3rem .9rem;
    color: #ffffff;
    border-radius: 0.25rem;
}

.form-control textarea {
    font-size: 1.3rem;
    height: 30vh;
}

.button {
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 2px solid transparent;
    padding: .5rem 1.3rem;
    font-size: 1.5rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #EE8A2C;
    border-color: #EE8A2C;
}

/* Use auto margins to center the button */
.buttonContact {
    margin-top: 40px;
    min-width: 150px;
    max-width: 200px;
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 2px 7px 18px -12px #000000a8;
}