.updatesContainer {
    background-color: #3a3939;
    padding-inline: 4vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Table {
    margin-top: 20px;
    margin-bottom: 25px;
}

.TableHead {
    background-color: rgb(29, 29, 29);
    padding-inline: 10px;
    height: 50px;
}

.TableBody {
    background-color: rgb(40, 40, 40);
}

.TableBody Tr:nth-child(even) {
    background-color: rgb(50, 50, 50);
    /* Background color for even rows */
}

.TableBody Tr:nth-child(odd) {
    background-color: rgb(40, 40, 40);
    /* Background color for odd rows */
}

.main {
    font-size: medium;
}

.sub {

    color: rgb(161, 161, 161)
}

.cell {
    max-width: 500px;
    padding: 10px;
    text-align: center;
    align-self: center;
    white-space: pre-line;
}

.cell ul {
    padding-left: 25px;
    margin-bottom: 5px;
    margin-top: 5px;
}

#description {
    text-align: left;
    font-size: 14px;
}