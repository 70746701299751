.downloadButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2vw;
}

.downloadButton {
    display: flex;
    background: linear-gradient(120deg, #3b3b3b, #252525);
    border-radius: 15px;
    padding: 0.6rem 1rem 0.8rem 0.6rem;
    width: clamp(120px, 22vw, 160px);
    /* min-width: 40px; */
    flex-direction: row;
    font-size: clamp(11px, 2.5vw, 15px);
    justify-content: flex-start;
    line-height: 1.1;
    align-items: center;
    font-weight: 600;
    border: 3px solid #EE8A2C;
    transition: 0.2s;
}

.downloadButton:hover {
    transition: 0.2s;
    transform: scale(0.92);
    border: 3px solid white;
}

.downloadButtonSmallText {
    font-size: clamp(9px, 2.1vw, 11px);
    font-weight: normal;
}

.iconImg {
    width: max(30px, 3vw);
    height: max(30px, 3vw);
    margin-right: 0.9rem;
}

.DownloadText {
    font-size: clamp(22px, 5vw, 37px);
    /* font-family: 'Francois One'; */
    margin-bottom: 1.5rem;
    padding: 0.2rem 1.4rem;
    text-shadow: 1px 1px 5px rgba(46, 46, 46, 0.164);
    box-shadow: 0px 0px 10px rgb(255, 255, 255);
    text-transform: uppercase;
    text-wrap: wrap;
    text-align: center;
    width: fit-content;
    font-weight: 700;
    color: rgb(255, 251, 247);
    background-color: #e77d19;
    border-radius: 25px 10px;
    transition: 0.2s;
}

.DownloadText:hover {
    transition: 0.2s;
    transform: scale(0.9);
}

@keyframes shad-anim {
    0% {
        background-position: 0 0
    }

    0% {
        background-position: 100% -100%
    }
}




.SloganText {
    font-size: max(30px, 2vw);
    font-family: 'Francois One';
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
    text-wrap: balance;
    transition: 0.2s;
}

.SloganText2 {
    text-align: center;
    text-wrap: balance;
    transition: 0.2s;
    color: var(--dark-color);
    font-weight: 600;
    padding: 0.5rem 1.4rem;
    text-shadow: 1px 1px 5px rgba(46, 46, 46, 0.164);
    box-shadow: 0px 0px 10px rgb(255, 255, 255);
    width: fit-content;
    background-color: #fa8b24;
    border-radius: 25px 10px;
    transition: 0.2s;
}

.SloganText:hover {
    transition: 0.2s;
    transform: translateY(-10px);
}

.SloganText2:hover {
    transition: 0.2s;
    transform: scale(0.9);
}

.LandingLeft {
    /* grid-area: left; */
    display: flex;
    flex-direction: column;
    padding-left: 2vw;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 1.5rem
        /* height: 27vw; */
}

.LandingRightSmall {
    display: none;
}

.LandingRight {
    flex: 1;
    background-color: #EE8A2C;
    height: 27vw;
}

.LandingContainer {
    height: 30vw;
    display: flex;

}

@media (max-width: 1250px) {
    /* .SloganText {
        font-size: max(2rem, 5vw);
        font-family: 'Francois One';
        padding-bottom: 1rem;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
        text-align: center;
    } */

    .LandingContainer {
        display: flex;
        flex-direction: column;
        justify-items: center;
        height: 100%;
        padding-top: 20px;

    }

    .LandingRight {
        flex: 0;
        display: none;
    }

    .LandingRightSmall {
        display: flex;
        flex: 1;
        background-color: #EE8A2C;
    }

    .LandingRightSmall img {
        height: 100%;
    }

    .LandingLeft {
        /* grid-area: left; */
        display: flex;
        flex-direction: column;
        padding-left: 2vw;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin-bottom: 30px;
    }
}



.content {
    position: relative;
}

.content h2 {
    color: #fff;
    /* font-size: 8em; */
    position: absolute;

    transform: translate(-50%, -120%);
}

.content h2:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 3px #11A8E4;
}

.content h2:nth-child(2) {
    color: #11A8E4;
    animation: animate 6s ease-in-out infinite;
}

@keyframes animate {

    0%,
    100% {
        clip-path: polygon(0% 45%,
                16% 44%,
                33% 50%,
                54% 60%,
                70% 61%,
                84% 59%,
                100% 52%,
                100% 100%,
                0% 100%);
    }

    50% {
        clip-path: polygon(0% 60%,
                15% 65%,
                34% 66%,
                51% 62%,
                67% 50%,
                84% 45%,
                100% 46%,
                100% 100%,
                0% 100%);
    }
}